import React from "react"
import { css, jsx } from "@emotion/core"

import CalendarIcon from "./calendarIcon"

const data = [
    {
    month: "Jun",
    date: "25",
    day: "Tuesday",
    time: "5:00 pm",
    location: "Claxton Veterans Community Center",
    title: "UGA Connected Town Hall Meeting",
  },
    {
    month: "June",
    date: "29",
    day: "Saturday",
    time: "5:30 pm",
    location: "Railroad Street, Hagan GA",
    title: "4th of July Festival",
  },
  {
    month: "Nov",
    date: "9",
    day: "Saturday",
    time: "7:00 am",
    location: "Evans County Recreation Department",
    title: "28th Annual Cruisin’ in the Country Ride",
  },
]

function Events(props) {
  const events = data
  const size = props.limit || data.length

  const eventItems = events
    .slice(0, size)
    .map(event => (
      <Event
        month={event.month}
        date={event.date}
        day={event.day}
        title={event.title}
        time={event.time}
        location={event.location}
      />
    ))

  return <div>{eventItems}</div>
}

const Event = ({ month, date, day, title, time, location }) => (
  <div
    css={{
      display: "flex",
      marginBottom: "1.5rem",
    }}
  >
    <div>
      <CalendarIcon month={month} date={date} day={day} />
    </div>
    <div
      css={{
        marginLeft: "0.667rem",
      }}
    >
      <div>
        <p css={{ fontWeight: "600" }}>{title}</p>
        <p>
          {time}
          {location != "" && ` at ${location}`}
        </p>
      </div>
    </div>
  </div>
)

export default Events
