import React from "react"
import { Link } from "gatsby"
import { css, jsx } from "@emotion/core"

const Button = ({ to, text, styles }) => {
  return (
    <Link
      css={{
        display: "inline-block",
        marginTop: "0.66rrem",
        backgroundColor: "rgb(9, 37, 86)",
        color: "#fff",
        textDecoration: "none",
        padding: "0.667rem",
        borderRadius: "2px",
        ...styles
      }}
      to={to}
    >
      {text}
    </Link>
  )
}

export default Button
