import React, { Component } from "react"
import PropTypes from "prop-types"
import { Global, css } from "@emotion/core"
import styled from "@emotion/styled"

import Navbar from "../global/navbar/navbar"
import Footer from "../global/footer/footer"

class SiteLayout extends Component {
  state = {
    navbarOpen: false,
  }

  handleNavbar = () => {
    this.setState({ navbarOpen: !this.state.navbarOpen })
  }

  render() {
    return (
      <div>
        <Global
          styles={css`
            *,
            *::after,
            *::before {
              margin: 0px;
              padding: 0px;
              box-sizing: inherit;
            }
            htiml {
              font-size: 62.5%;
              -webkit-tap-highlight-color: transparent;
              font-size: 16px;
              background-color: #f1f2f7;
            }
            body {
              margin: 0;
              font-family: "Open Sans", sans-serif;
              font-weight: 400;
              line-height: 1.5;
              color: #333;
              box-sizing: border-box;
              background-color: #f1f2f7;
            }
            main {
              margin-top: 5rem;
              padding: ${this.props.padding};
              min-height: 700px;
            }
            .noMinHeight {
              min-hight: 0 !important;
            }
            h1 {
              font-size: 3.375em;
              margin-bottom: 1rem;
              font-weight: 400;
            }
            h2 {
              font-weight: 400;
            }
            h3 {
              font-size: 1.5em;
              font-weight: 400;
            }
          `}
        />
        <Navbar
          navbarState={this.state.navbarOpen}
          handleNavbar={this.handleNavbar}
          testProp="CECC Website"
        />
        <main>{this.props.children}</main>
        <div css={{ clear: "both" }}></div>
        <Footer />
      </div>
    )
  }
}

SiteLayout.propTypes = {
  children: PropTypes.node.isRequired,
  padding: PropTypes.string,
}

SiteLayout.defaultProps = {
  padding: "32px",
}

export default SiteLayout
