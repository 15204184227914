import React from "react"
import { css, jsx } from "@emotion/core"

const CalendarIcon = ({ month, date, day }) => {
  return (
    <div
      css={{
        border: "1px solid rgb(171, 173, 179)",
        textAlign: "center",
        width: "3.8rem",
        backgroundColor: "#fff",
      }}
    >
      <div
        css={{
          backgroundColor: "rgb(171, 173, 179)",
          fontSize: "0.8rem",
          fontWeight: "600",
        }}
      >
        {month}
      </div>
      <div
        css={{
          fontSize: "1.3rem",
          padding: "0.2rem 0 0 0",
          lineHeigh: " 1.3",
        }}
      >
        {date}
      </div>
      <div
        css={{
          marginTop: "-3px",
          fontSize: "0.7rem",
        }}
      >
        {day}
      </div>
    </div>
  )
}

export default CalendarIcon
