import React from "react"
import { css, jsx } from "@emotion/core"
import { Link } from "gatsby"

function Footer(props) {
  return (
    <footer
      css={{
        marginTop: "2.667rem",
        backgroundColor: "rgb(45, 52, 54)",
        borderTop: "1px solid #141c1f",
        padding: "32px",
      }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Section>
          <div
            css={{
              marginBottom: "1rem",
            }}
          >
            <p>About Us</p>
            <Links
              links={[
                { to: "/about", text: "The Chamber" },
                { to: "/about/team", text: "Our Team" },
                { to: "/about/membership", text: "Membership Benefits" },
                { to: "/about/calendar", text: "Events Calendar" },
              ]}
            />
          </div>
          <div>
            <p>Industry</p>
            <Links
              links={[
                {
                  to: "/industry/membership-directory",
                  text: "Membership Directory",
                },
                {
                  to: "/industry/downtown-development-authority",
                  text: "Downtown Development Authority",
                },
                {
                  to: "/industry/ecconomic-development-authority",
                  text: "Ecconomic Development Authority",
                },
              ]}
            />
          </div>
        </Section>
        <Section>
          <div>
            <p>Our Community</p>
            <Links
              links={[
                { to: "/community/government", text: "Government" },
                { to: "/community/education", text: "Education" },
                { to: "/community/healthcare", text: "Healthcare" },
                { to: "/community/recreation", text: "Recreation" },
                { to: "/community/attractions", text: "Attractions" },
              ]}
            />
          </div>
        </Section>
        <Section>
          <div>
            <p>Programs</p>
            <Links
              links={[
                { to: "/programs/leadership-evans", text: "Leadership Evans" },
                {
                  to: "/programs/business-after-hours",
                  text: "Business After Hours",
                },
                { to: "/programs/chamber-banquet", text: "Chamber Banquet" },
                {
                  to: "/programs/claxton-wildlife-festival",
                  text: "Rattlesnake & Wildlife Festival",
                },
                {
                  to: "/programs/national-day-of-prayer",
                  text: "National Day of Prayer",
                },
                {
                  to: "/programs/rotary-chamber-auction",
                  text: "Rotary/Chamber Auction",
                },
                {
                  to: "/programs/trick-or-treat",
                  text: "Trick or Treat - Kid's Safety Day",
                },
                {
                  to: "/programs/cruisin-in-the-country",
                  text: "Cruisin’ in the Country",
                },
                {
                  to: "/programs/christmas-in-claxton",
                  text: "Christmas in Claxton",
                },
              ]}
            />
          </div>
        </Section>
        <Section>
          <div>
            <p>Contact Us</p>
            <div
              css={{
                fontWeight: "300",
                color: "#dce4e6",
                "& p": {
                  marginBottom: "1rem",
                  "& span": {
                    fontWeight: "400",
                  },
                },
              }}
            >
              <p>
                302 West Railroad Street
                <br />
                Claxton, Georgia 30417
              </p>
              <p>
                <span>P:</span> 912.739.1391
                <br />
                <span>F:</span> 912.739.3827
              </p>
              <p>
                <span>Hours:</span> M-F 8am-5pm
              </p>
            </div>
            <a
              css={{
                display: "inline-block",
                padding: "0.667rem",
                color: "#dce4e6",
                border: "1px solid #dce4e6",
                borderRadius: "2px",
                textDecoration: "none",
                marginTop: "1rem",
                "&:hover": {
                  backgroundColor: "#dce4e6",
                  color: "#2d3436",
                },
              }}
              href="https://www.facebook.com/claxtonevanschamber/"
            >
              Follow Us On Facebook
            </a>
          </div>
        </Section>
      </div>
      <div
        css={{
          display: "flex",
          borderTop: "1px solid #dce4e6",
          borderBottom: "1px solid #dce4e6",
          marginTop: "50px",
          color: "#dce4e6",
          padding: "0.667rem 2rem",
          justifyContent: "space-between",
        }}
      >
        <div css={{}}>
          <small>
            Copyright &copy; 2021 - Claxton-Evans County Chamber of Commerce -
            All Rights Reserved
          </small>
        </div>
        <div>
          <small
            css={{
              "& a": {
                textDecoration: "none",
                display: "inline-block",
                paddingLeft: "1rem",
                color: "#dce4e6",
                "&:hover": {
                  textDecoration: "underline",
                },
              },
            }}
          >
            <Link to="/terms">Terms & Conditions</Link>
            <Link to="/site-map">Sitemap</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </small>
        </div>
      </div>
      <div
        css={{
          textAlign: "center",
          color: "#6e7a7d",
          padding: "0.667rem 0",
          "& a": {
            color: "#6e7a7d",
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
          },
        }}
      >
        <small>
          Site designed by{" "}
          <a href="https://www.poulinmediamarketing.com">
            Poulin Media Marketing, LLC
          </a>
        </small>
      </div>
    </footer>
  )
}

function Section(props) {
  return (
    <div
      css={{
        width: "25%",
        color: "#fff",
        padding: "0 20px",
      }}
    >
      {props.children}
    </div>
  )
}

function Links(props) {
  const items = props.links

  const linkItems = items.map(item => (
    <li key={item.to.toString}>
      <Link
        to={item.to}
        css={{
          color: "#dce4e6",
          textDecoration: "none",
          fontWeight: "300",
          ":hover": {
            textDecoration: "underline",
          },
        }}
      >
        {item.text}
      </Link>
    </li>
  ))

  return (
    <ul
      css={{
        listStyle: "none",
      }}
    >
      {linkItems}
    </ul>
  )
}

export default Footer
